<template>
  <CreateUpdateTemplate
    :routePreventDialog.sync="routePreventDialog"
    :customClass="'customer-create create-form'"
    v-if="getPermission('customer:create')"
  >
    <template v-slot:header-title>
      <h1 class="form-title d-flex">Create a new Customer</h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack"
        :disabled="formLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="onSubmit"
        color="cyan"
      >
        Save Customer
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="customerForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="onSubmit"
      >
        <v-tabs
          v-model="customerTab"
          background-color="transparent"
          centered
          color="cyan"
          fixed-tabs
          class="custom-tab-transparent"
        >
          <template v-for="(tab, index) in tabs">
            <v-tab
              class="font-size-16 font-weight-600 px-8"
              v-if="getPermission(tab.permission + ':create')"
              :key="`customer-tab-${index}`"
              :href="'#tab-' + tab.key"
            >
              <v-icon color="grey darken-4" left>{{ tab.icon }}</v-icon>
              {{ tab.title }}
            </v-tab>
          </template>
        </v-tabs>
        <v-tabs-items v-model="customerTab">
          <v-tab-item :value="'tab-details'">
            <BasicDetail
              :prospect="prospect"
              v-on:save-details="updateContactDetail($event)"
            ></BasicDetail>
          </v-tab-item>
          <v-tab-item
            v-if="getPermission('contact-person:create')"
            :value="'tab-contact-person'"
          >
            <ContactPersons
              v-bind:detail="contactDetail"
              v-on:saveContactPerson="updateContactPerson"
            ></ContactPersons>
          </v-tab-item>
          <v-tab-item :value="'tab-billing'">
            <BillingAddress
              :prospect="prospect"
              v-on:saveBillingAddress="updateBillingAddress"
            ></BillingAddress>
          </v-tab-item>
          <v-tab-item v-if="false" :value="'tab-property'">
            <PropertyAddress
              :lead.sync="lead_details"
              :proposal.sync="proposal_details"
              v-bind:billing="billingAddress"
              v-on:savePropertyAddress="updatePropertyAddress"
            ></PropertyAddress>
          </v-tab-item>
          <v-tab-item :value="'tab-notification'">
            <Notification
              :options.sync="options"
              v-on:saveNotification="updateNotification"
            ></Notification>
          </v-tab-item>
        </v-tabs-items>
      </v-form>
    </template>
  </CreateUpdateTemplate>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  QUERY,
  POST,
  PUT,
  CLEAR_ERROR,
  GET,
} from "@/core/services/store/request.module";
import BasicDetail from "@/view/pages/customer/Basic-Detail.vue";
// import ContactDetails from "@/view/pages/customer/create-or-update/Contact-Details.vue";
import ContactPersons from "@/view/pages/customer/create-or-update/Contact-Persons.vue";
import PropertyAddress from "@/view/pages/customer/create-or-update/Property-Address.vue";
import BillingAddress from "@/view/pages/customer/create-or-update/Billing-Address.vue";
import Notification from "@/view/pages/customer/create-or-update/Notification.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import ObjectPath from "object-path";

export default {
  mixins: [CommonMixin],
  name: "customer-create",
  title: "Create Customer",
  data() {
    return {
      lead: null,
      lead_details: {},
      proposal: null,
      proposal_details: {},
      propertyAddress: {},
      billingAddress: {},
      contactDetail: {},
      contactPerson: [],
      notification: {},
      tabs: [
        {
          title: "Contact Details",
          icon: "mdi-phone",
          key: "details",
          permission: "customer",
        },
        {
          title: "Contact Persons",
          icon: "mdi-account-multiple",
          key: "contact-person",
          permission: "contact-person",
        },
        {
          title: "Site Address",
          icon: "mdi-credit-card",
          key: "billing",
          permission: "customer",
        },
        /*{
          title: "Service Address",
          icon: "mdi-home-map-marker",
          key: "property",
          permission: "property",
        },*/
        {
          title: "Remarks",
          icon: "mdi-bell-ring",
          key: "notification",
          permission: "customer",
        },
      ],
      customerTab: "tab-details",
      isCustomerCreated: false,
      isAddressCreated: false,
      customerId: 0,
      prospect_id: 0,
      options: {},
      prospect: {},
    };
  },
  components: {
    BasicDetail,
    // ContactDetails,
    ContactPersons,
    PropertyAddress,
    BillingAddress,
    Notification,
    CreateUpdateTemplate,
  },
  watch: {
    customerTab() {
      const _this = this;
      setTimeout(function () {
        _this.$refs.customerForm.validate();
      }, 200);
    },
    formValid(param) {
      if (param) {
        this.$store.dispatch(CLEAR_ERROR, {});
      }
    },
  },
  methods: {
    updatePropertyAddress(param) {
      this.propertyAddress = param;
    },
    updateBillingAddress(param) {
      this.billingAddress = param;
    },
    updateContactDetail(param) {
      this.contactDetail = param;
    },
    updateContactPerson(param) {
      this.contactPerson = param;
    },
    updateNotification(param) {
      this.notification = param;
    },
    getOptions() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "customer/options",
          data: { prospect: _this.prospect_id },
        })
        .then(({ data }) => {
          _this.options = data.options;
          _this.prospect = data.prospect;
          if (_this.prospect_id > 0) {
            if (!_this.prospect) {
              _this.goBack();
            }
          }
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    createOrUpdatedAddress(customer) {
      return new Promise((resolve, reject) => {
        try {
          if (customer) {
            const _this = this;
            const addressArr = [];
            /*if (_this.lodash.isEmpty(_this.propertyAddress) === false) {
              addressArr.push(_this.propertyAddress);
            }*/
            if (_this.lodash.isEmpty(_this.billingAddress) === false) {
              addressArr.push(_this.billingAddress);
            }

            if (_this.lodash.isEmpty(addressArr) === false) {
              const formData = { address: addressArr, type: "customer" };
              _this.$store
                .dispatch(PUT, { url: "address/" + customer, data: formData })
                .then((response) => {
                  resolve(response);
                })
                .catch((error) => {
                  reject(error);
                });
            } else {
              resolve(true);
            }
          } else {
            resolve(true);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    makeDefaultPerson() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          let emailData = _this.lodash.filter(
            _this.contactDetail.details,
            function (data) {
              return data.type === 1;
            }
          );
          /*let contactData = _this.lodash.filter(
            _this.contactDetail.details,
            function (data) {
              return data.type === 2;
            }
          );*/
          let firstEntry = {
            id: null,
            title: _this.contactDetail.salutation,
            first_name: _this.contactDetail.first_name,
            last_name: _this.contactDetail.last_name,
            will_notified: 1,
            position: null,
            display_name: _this.createDisplayName(),
            primary_email: _this.lodash.head(emailData)
              ? _this.lodash.head(emailData).value
              : null,
            primary_phone: _this.lodash.toString(
              _this.contactDetail.phone_number
            ),
            default: 1,
          };
          resolve([firstEntry]);
        } catch (error) {
          reject(error);
        }
      });
    },
    createDisplayName() {
      const _this = this;
      let firstname = "";
      if (_this.contactDetail.first_name) {
        firstname = _this.contactDetail.first_name.toLowerCase();
      }
      let lastname = "";
      if (_this.contactDetail.last_name) {
        lastname = _this.contactDetail.last_name.toLowerCase();
      }
      return firstname + lastname;
    },
    async onSubmit() {
      const _this = this;
      try {
        const formErrors = _this.validateForm(_this.$refs.customerForm);
        if (formErrors.length) {
          for (let i = 0; i < formErrors.length; i++) {
            ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
          }
          return false;
        }

        if (_this.lodash.isEmpty(_this.billingAddress)) {
          _this.customerTab = "tab-billing";
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Site Address is required")
          );
          return false;
        }

        /*if (!_this.billingAddress.unit_no) {
          _this.customerTab = "tab-billing";
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Site Address Unit No is required")
          );
          return false;
        }*/

        if (!_this.billingAddress.street_1) {
          _this.customerTab = "tab-billing";
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Site Address Street 1 is required")
          );
          return false;
        }

        if (!_this.billingAddress.zip_code) {
          _this.customerTab = "tab-billing";
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Site Address Postal Code is required")
          );
          return false;
        }

        /*if (_this.lodash.isEmpty(_this.propertyAddress)) {
          _this.customerTab = "tab-property";
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Service address is required")
          );
          return false;
        }*/

        if (!_this.$refs.customerForm.validate()) {
          return false;
        }

        //_this.formLoading = true;
        _this.$store.dispatch(CLEAR_ERROR, {});

        if (_this.lodash.isEmpty(_this.contactPerson)) {
          _this.contactPerson = await _this.makeDefaultPerson();
        }

        let formData = _this.contactDetail;

        if (_this.lodash.isEmpty(_this.contactDetail.profile_logo) === false) {
          formData.profile_logo = _this.contactDetail.profile_logo.id;
        }

        if (_this.lodash.isEmpty(_this.contactPerson) === false) {
          formData.persons = _this.contactPerson;
        }

        formData.prospect = _this.prospect_id;

        formData.lead =
          _this.lodash.toSafeInteger(_this.$route.query.lead) || null;
        formData.proposal =
          _this.lodash.toSafeInteger(_this.$route.query.proposal) || null;
        formData.client_reminders = 0;
        formData.client_follow_up_emails = 0;
        formData.print_email_on_work_order = 0;
        formData.customer_remark = _this.options.remarks;

        if (_this.lodash.isEmpty(_this.notification) === false) {
          formData.client_reminders = _this.lodash.toSafeInteger(
            _this.notification.client_reminders
          );
          formData.client_follow_up_emails = _this.lodash.toSafeInteger(
            _this.notification.client_follow_up_emails
          );
          formData.print_email_on_work_order = _this.lodash.toSafeInteger(
            _this.notification.print_email_on_work_order
          );
          formData.customer_remark = _this.lodash.toString(
            _this.notification.customer_remark
          );
        }

        _this.formLoading = true;
        try {
          if (!_this.isCustomerCreated) {
            let customerObject = await _this.$store.dispatch(POST, {
              url: "customer",
              data: formData,
            });
            _this.customerId = customerObject.data.id;
            _this.isCustomerCreated = true;
            _this.backForce = true;
          }
        } catch (error) {
          ErrorEventBus.$emit(
            "update:error",
            "Customer is not created. Please try again."
          );
        }

        try {
          if (!_this.isAddressCreated) {
            await _this.createOrUpdatedAddress(_this.customerId);
            _this.isAddressCreated = true;
          }
        } catch (error) {
          _this.formLoading = false;
          ErrorEventBus.$emit(
            "update:error",
            "Service or Site Address is not created. Please try again."
          );
          return false;
        }

        _this.formLoading = false;

        if (_this.isCustomerCreated) {
          _this.$router.push(
            _this.getDefaultRoute("customer.detail", {
              params: { id: _this.customerId },
            })
          );
        }
      } catch (error) {
        ErrorEventBus.$emit("update:error", InitializeError(error));
      }
    },
    getLead() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "leads/" + _this.lead,
        })
        .then(({ data }) => {
          _this.lead_details = data;
          if (data.country || data.zip) {
            _this.propertyAddress = {
              id: null,
              type: 1,
              is_same: 1,
              unit_no: data.unit_no,
              street_1: data.address_1,
              street_2: data.address_2,
              country: data.country,
              zip_code: data.postal_code,
              latitude: null,
              longitude: null,
              remarks: null,
            };

            _this.billingAddress = {
              id: null,
              type: 2,
              is_same: 0,
              unit_no: data.unit_no,
              street_1: data.address_1,
              street_2: data.address_2,
              country: data.country,
              zip_code: data.postal_code,
              latitude: null,
              longitude: null,
              remarks: null,
            };
          }
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getProposal() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "proposals/" + _this.proposal,
        })
        .then(({ data }) => {
          _this.proposal_details = data;

          if (data.country || data.zip) {
            _this.propertyAddress = {
              id: null,
              type: 1,
              is_same: 1,
              unit_no: data.unit_no,
              street_1: data.address_1,
              street_2: data.address_2,
              country: data.country,
              zip_code: data.zip,
              latitude: null,
              longitude: null,
              remarks: null,
            };

            _this.billingAddress = {
              id: null,
              type: 2,
              is_same: 0,
              unit_no: data.unit_no,
              street_1: data.address_1,
              street_2: data.address_2,
              country: data.country,
              zip_code: data.zip,
              latitude: null,
              longitude: null,
              remarks: null,
            };
          }
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
  },
  mounted() {
    this.getOptions();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Customer", route: "customer" },
      { title: "Create" },
    ]);

    if (this.lead > 0) {
      this.getLead();
    }

    if (this.proposal > 0) {
      this.getProposal();
    }
  },
  beforeMount() {
    this.prospect_id = ObjectPath.get(this.$route, "query.prospect", 0);
    this.lead = ObjectPath.get(this.$route, "query.lead", 0);
    this.proposal = ObjectPath.get(this.$route, "query.proposal", 0);
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.backForce) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
};
</script>
